import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/configureStore";
import { IntercomProvider } from "react-intercom-hook";
import { Provider } from "react-redux";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorHandler } from "./pageloader";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import { PersistGate } from "redux-persist/lib/integration/react";
import { persistStore } from "redux-persist";
import { createBrowserHistory } from "history";

import "./i18n";

let persistor = persistStore(store);

// process.env.NODE_ENV !== "development" &&
//   Sentry.init({
//     dsn: process.env.REACT_APP_SENTRY_DSN,
//     integrations: [new BrowserTracing()],
//     tracesSampleRate: 1.0,
//   });

const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ErrorBoundary FallbackComponent={ErrorHandler}>
          <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
            <Router history={history}>
              <App />
            </Router>
          </IntercomProvider>
        </ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
