import React from "react";
import styled from "styled-components";

const BankSelectfield = (props) => {
  return (
    <Container error={props.error} full={props.full ? props.full : undefined}>
      <div className="group">
        <select onChange={props.onChange} name={props.name} id={props.id} on>
          <option value="">{props.placeholder}</option>
          {props.data.map((item, index) => {
            return (
              <option
                // style={{ backgroundImage: `url(${bankLogo})` }}
                key={index}
                value={item?.withdrawal_options_id}
                selected={index === props.data.length - 1}
              >
                {/* <img src={bankLogo} alt={bankName} /> */}
                {item?.withdrawal_details?.bank_name} &nbsp;&nbsp; *** ***{" "}
                {item?.withdrawal_details?.account_number?.slice(-4)}
              </option>
            );
          })}
        </select>
      </div>
    </Container>
  );
};

export default BankSelectfield;

const Container = styled.div`
  position: relative;
  width: ${({ full }) => (full ? "100%" : "50%")};
  margin: 0 !important;
  padding: 0 !important;

  .group {
    display: flex;
    align-items: center;
    position: relative;
    height: 3rem;

    select {
      width: 100%;
      border: ${({ error }) =>
        error ? "1px solid #E15554" : "1px solid rgba(42, 67, 75, 0.15)"};
      background: transparent;
      padding: 0 16px;
      height: 100%;
      box-sizing: border-box;
      border-radius: 4px;
      color: #2a434b;
      -webkit-appearance: none;
      appearance: none;

      p {
        font-size: 9px;
        line-height: 120%;
        color: #6a7b81;
        opacity: 0.8;
      }

      h4 {
        font-size: 13px;
        line-height: 120%;
        color: #2a434b;
        opacity: 0.8;
      }

      :focus {
        outline: 4px solid #f6a20a0e;
        border: 1px solid #fce3b6;
      }

      :hover {
        border: 1px solid #fce3b6;
      }

      .text_group {
        color: red;
      }

      option {
        display: flex;
        align-items: center;
        gap: 8px;
        background: red !important;

        img {
          width: 32px;
          height: 32px;
          object-fit: fill;
        }

        .text_group {
          display: flex;
          flex-direction: column;
          gap: 3px;
        }
      }
    }
  }

  .group::after {
    content: "▾";
    color: #2a434b;
    font-size: 1rem;
    right: 15px;
    position: absolute;
  }
`;
