import { createSlice } from "@reduxjs/toolkit";
import { getBetstartersGameUrl } from "../../actions/slot/getGames";

export const betstartersGameStateSlice = createSlice({
  name: "betstartersGameState",
  initialState: {
    fullScreen: true,
    showGameCanvas: false,
    selectedGame: null,
    loading: false,
    success: false,
    error: false,
    errors: null,
    slot_url: null,
  },
  reducers: {
    toggleFullScreen: (state) => {
      state.fullScreen = !state.fullScreen;
      return state;
    },
    handleSelectedGame: (state, { payload }) => {
      state.selectedGame = payload;
      return state;
    },
    toggleShowModal: (state) => {
      state.showGameCanvas = !state.showGameCanvas;
      return state;
    },
    clearState: (state) => {
      state.loading = false;
      state.error = false;
      state.errors = null;
      state.success = false;
      return state;
    },
  },
  extraReducers: {
    [getBetstartersGameUrl.pending]: (state) => {
      state.loading = true;
    },
    [getBetstartersGameUrl.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.slot_url = payload.play_for_real;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
    [getBetstartersGameUrl.rejected]: (state, { payload }) => {
      state.errors = payload.message;
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});

export const {
  toggleFullScreen,
  handleSelectedGame,
  toggleShowModal,
  clearState,
} = betstartersGameStateSlice.actions;

export const betstartersGameStateSelector = (state) =>
  state.betstartersGameState;
