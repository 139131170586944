import { createSlice } from "@reduxjs/toolkit";
import { getBingoHistory } from "../../actions/bingo/getBingoHistory";

export const bingoHistorySlice = createSlice({
  name: "bingoHistory",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
    data: [],
  },
  reducers: {
    clearState: (state) => {
      state.loading = false;
      state.error = false;
      state.errors = null;
      state.success = false;
      state.data = [];
      return state;
    },
  },
  extraReducers: {
    [getBingoHistory.pending]: (state) => {
      state.loading = true;
    },
    [getBingoHistory.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.data = payload;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
    [getBingoHistory.rejected]: (state, { payload }) => {
      state.errors = payload;
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});

export const { clearState } = bingoHistorySlice.actions;

export const bingoHistorySelector = (state) => state.bingoHistory;
