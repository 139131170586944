import { createSlice } from "@reduxjs/toolkit";
import { initializeFundWalletCd } from "../../actions/fundWallet";

export const fundWalletCdSlice = createSlice({
  name: "fundWalletCd",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
    data: {},
  },
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
  },
  extraReducers: {
    [initializeFundWalletCd.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [initializeFundWalletCd.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.data = payload;
      return state;
    },
    [initializeFundWalletCd.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.data = {};
      state.errors = payload.message;
      return state;
    },
  },
});
export const { clearState } = fundWalletCdSlice.actions;
export const fundWalletCdSelector = (state) => state.fundWalletCd;
