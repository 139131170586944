import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { Routes } from "../utilities/constants";
import { checkAuthToken } from "../utilities/checkAuthToken";

const PrivateRoute = ({ component: Component, ...rest }) => {
  let isAuthorized = false;
  let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
  const rememberSession = localStorage.getItem("rememberSession");
  if (rememberSession) {
    tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
  }
  if (tokenData !== null) {
    const { accessToken, expiresIn } = tokenData;
    if (accessToken) {
      let currentTime = +new Date() / 1000;
      if (currentTime < expiresIn) {
        isAuthorized = true;
      }
    }
  }

  if (!isAuthorized) {
    localStorage.removeItem("persist:profile");
    localStorage.removeItem("persist:getSlot");
    localStorage.removeItem("persist:getWallet");
    sessionStorage.removeItem("betty_tkd");
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthorized ? (
          <Component {...rest} {...props} />
        ) : (
          <Redirect to={Routes.signIn} />
        )
      }
    />
  );
};

export default PrivateRoute;
