import React from 'react';
import Skeleton from "react-loading-skeleton";
//Page loading style

const Preloader = () => {
    return (
      <div style={{ margin: "20% 40%" }}>
        <Skeleton count={5} enableAnimation />
      </div>
    );
}

export default Preloader;