import { createAsyncThunk } from "@reduxjs/toolkit";

export const initializeFundWalletNg = createAsyncThunk(
  "initialize/fund-wallet/ng",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const { channel } = body;

      const { email, amount, phone_num } = body;
      const values1 = { email, amount };
      const values2 = { email, amount, phone_num, provider_method: "Paga" };

      const payload = channel === "paga" ? values2 : values1;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/wallet-deposit/${channel}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);
export const initializeFundWalletNgSquad = createAsyncThunk(
  "initialize/fund-wallet/ng",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const { channel } = body;

      const { email, amount } = body;
      const values1 = { email, amount };

      const payload = values1;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/wallet-deposit/${channel}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);

export const initializeFundWalletNgQuickteller = createAsyncThunk(
  "initialize/fund-wallet/ng/quickteller",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const { channel } = body;

      const { email, amount } = body;

      const values1 = { email, amount };
      const payload = values1;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/wallet-deposit/${channel}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(payload),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);

export const getPagaAcctNum = createAsyncThunk(
  "paga,acct",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/wallet-deposit/paga`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);

export const initializeFundWallet = createAsyncThunk(
  "initialize/fund-wallet/gh",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const { amount, phone_num, provider,channel } = body;
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/wallet-deposit/${provider}/${channel}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify({ amount, phone_num }),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);
export const initializeFundWalletCd = createAsyncThunk(
  "initialize/fund-wallet/gh",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const { amount, phone_num, provider } = body;
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/wallet-deposit/unipesa/${provider}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify({ amount, phone_num }),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);

export const getMonnifyAcctNum = createAsyncThunk(
  "monnify,acct",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/wallet-deposit/monnify`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);
