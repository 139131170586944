import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  handleShowFilterModal,
  settingsSelector,
} from "../redux/reducers/profile/settings";

const CustomModal = (props) => {
  const dispatch = useDispatch();
  const { filterModal } = useSelector(settingsSelector);
  return (
    filterModal && (
      <Container>
        <div
          className="overlay"
          onClick={() => dispatch(handleShowFilterModal())}
        />
        <div className="bottom">
          <div className="modal">{props.content}</div>
        </div>
      </Container>
    )
  );
};

export default CustomModal;

const Container = styled.div`
  .overlay {
    background-color: #2a434b3a;
    max-width: 425px;
    top:0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    position: absolute;
  }

  .bottom {
    position: absolute;
    max-width: 425px;
    width: 100%;
    bottom: 0;
    z-index: 20000;
  }

  .modal {
    width: 100%;
    padding: 20px 16px 16px;
    background: white;
    border-radius: 20px 20px 0 0;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
`;
