import { combineReducers } from "redux";
/* start of import reducers */
//Login
import { signinSlice } from "./auth/signin";
//Register
import { setPinSlice } from "./auth/signup/setPin";
import { signupSlice } from "./auth/signup/signup";
import { verifySignupSlice } from "./auth/signup/verify";
//Forgot Password
import { getOtpSlice } from "./auth/forgotPassword/getOtp";
import { resetPinSlice } from "./auth/forgotPassword/resetPin";
import { verifyResetOtpSlice } from "./auth/forgotPassword/verify";
//Profile
import { changePinSlice } from "./profile/changePin";
import { settingsSlice, profileSlice } from "./profile/index.jsx";
import { getLocationSlice } from "./getLocation";
//Slots
import { getSlotGamesSlice } from "./slot/getGames";
import { getNewGamesSlice } from "./slot/getNewGames.jsx";
import { gameStateSlice } from "./slot/gameState";
import { newGameStateSlice } from "./slot/jdbGameState.jsx";
import { betstartersGameStateSlice } from "./slot/betstarterGameState";
import { getSlotTransactionSlice } from "./slot/getSlotTransactions";
//Bingo.
import { bingoStateSlice } from "./bingo/getBingoGames";
import { bingoHistorySlice } from "./bingo/getBingoHistory";
//Wallet
import { getWalletSlice } from "./wallet/getWallet";
import { depositSlice } from "./transactions/deposit";
import { withdrawalSlice } from "./wallet/withdrawal";
import { getWithdrawalsSlice } from "./transactions/withdrawal";
import { addBankAccountSlice } from "./wallet/addBankAccount";
import { fundWalletNgSlice } from "./fundWallet/nigeria";
import { fundWalletGhSlice } from "./fundWallet/ghana";
import { fundWalletCdSlice } from "./fundWallet/congo";

/* end of import reducers */

import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { updateEmailSlice } from "./profile/updateEmail";
import { verifyEmailOtpSlice } from "./profile/verifyEmailOtp";
import { verifyPhoneSlice } from "./auth";
// analytics
import {
  depositSegmentSlice,
  loginSegmentSlice,
  signupSegmentSlice,
} from "./analytics";

const profilePersistConfig = {
  key: "profile",
  storage,
  whitelist: ["user"],
};

const getSlotGamesPersistConfig = {
  key: "getSlotGames",
  storage,
  whitelist: ["games"],
};
const getNewGamesPersistConfig = {
  key: "getNewGames",
  storage,
  whitelist: ["games"],
};

const getBingoEventsPersistConfig = {
  key: "getBingoGames",
  storage,
  whitelist: ["bingo"],
};

const getWalletPersistConfig = {
  key: "getWallet",
  storage,
  whitelist: ["wallet_data"],
};
const getLocationPersistConfig = {
  key: "getLocation",
  storage,
  whitelist: ["location"],
};

export const reducer = combineReducers({
  // Login
  signin: signinSlice.reducer,
  // Register
  signup: signupSlice.reducer,
  verifySignup: verifySignupSlice.reducer,
  setPin: setPinSlice.reducer,
  verifyPhone: verifyPhoneSlice.reducer,
  // Forgot Password
  getOtp: getOtpSlice.reducer,
  resetPin: resetPinSlice.reducer,
  verifyResetOtp: verifyResetOtpSlice.reducer,
  // Profile
  changePin: changePinSlice.reducer,
  updateEmail: updateEmailSlice.reducer,
  verifyEmailOtp: verifyEmailOtpSlice.reducer,
  settings: settingsSlice.reducer,
  profile: persistReducer(profilePersistConfig, profileSlice.reducer),
  // Slots
  getSlotGames: persistReducer(
    getSlotGamesPersistConfig,
    getSlotGamesSlice.reducer
  ),

  getNewGames: persistReducer(
    getNewGamesPersistConfig,
    getNewGamesSlice.reducer
  ),
  // Location
  getLocation: persistReducer(
    getLocationPersistConfig,
    getLocationSlice.reducer
  ),
  // Bingo
  getBingoGames: persistReducer(
    getBingoEventsPersistConfig,
    bingoStateSlice.reducer
  ),
  gameState: gameStateSlice.reducer,
  newGameState:newGameStateSlice.reducer,
  betstartersGameState: betstartersGameStateSlice.reducer,
  bingoHistory: bingoHistorySlice.reducer,
  getSlotTransactions: getSlotTransactionSlice.reducer,
  // Wallet
  getWallet: persistReducer(getWalletPersistConfig, getWalletSlice.reducer),
  fundWalletNg: fundWalletNgSlice.reducer,
  fundWalletGh: fundWalletGhSlice.reducer,
  fundWalletCd: fundWalletCdSlice.reducer,
  deposit: depositSlice.reducer,
  withdrawal: withdrawalSlice.reducer,
  getWithdrawals: getWithdrawalsSlice.reducer,
  addBankAccount: addBankAccountSlice.reducer,

  // analytics
  loginAnalytics: loginSegmentSlice.reducer,
  signupAnalytics: signupSegmentSlice.reducer,
  depositAnalytics: depositSegmentSlice.reducer,
});
