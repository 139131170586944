import { createSlice } from "@reduxjs/toolkit";
import { initializeFundWallet } from "../../actions/fundWallet";

export const fundWalletGhSlice = createSlice({
  name: "fundWalletGh",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
    data: {},
  },
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
  },
  extraReducers: {
    [initializeFundWallet.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [initializeFundWallet.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.data = payload;
      return state;
    },
    [initializeFundWallet.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.data = {};
      state.errors = payload.message;
      return state;
    },
  },
});
export const { clearState } = fundWalletGhSlice.actions;
export const fundWalletGhSelector = (state) => state.fundWalletGh;
