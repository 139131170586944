import { createSlice } from "@reduxjs/toolkit";
import { getSlotGames } from "../../actions/slot/getGames";

export const getSlotGamesSlice = createSlice({
  name: "getSlotGames",
  initialState: {
    games: null,
    loading: false,
    success: false,
    error: false,
    errors: null,
    pagination: null,
  },
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
  },
  extraReducers: {
    [getSlotGames.pending]: (state) => {
      state.loading = true;
    },
    [getSlotGames.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.games = payload?.directory;
      state.error = false;
      state.errors = null;
      state.loading = false;
      state.pagination = payload?.pagination;
      return state;
    },
    [getSlotGames.rejected]: (state, { payload }) => {
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});

export const { clearState } = getSlotGamesSlice.actions;

export const getSlotGamesSelector = (state) => state.getSlotGames;
