/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { getLocation, getLocaleProfile } from "../../redux/actions/getLocation";
import {
  getLocationSelector,
  handleShowModal,
  setLocation,
} from "../../redux/reducers/getLocation";
import { Spin } from "antd";
import { antIcon } from "../../utilities/constants";
import { defaultCountries, DisclaimerModal, SelectCountryModal } from "./modal";
import { useLocation } from "react-router-dom";
import axios from "axios";

const LayoutView = ({ children }) => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const searchParams = useLocation().search;

  const { loading, showModal, location } = useSelector(getLocationSelector);

  let defaultCountry = false;

  const getCountry = () => {
    let code;
    let url = window.location.host;
    let locationArray = url.split(".");

    if (url.includes("bettybingozm")) {
      code = "zm";
    } else if (url.includes("bettydrc")) {
      code = "drc";
    } else if (url.includes("bettybingo")) {
      code = locationArray[locationArray.length - 1];
    } else {
      code = locationArray[0];
    }
    if (code.length > 3) {
      dispatch(getLocation());
    } else {
      const country = defaultCountries?.find(
        (country) => country.country_code === code
      );
      if (country) {
        dispatch(setLocation(country));
      } else {
        isShowModal();
      }
    }
  };

  const getUserIp = async () => {
    const res = await axios.get("https://ipapi.co/json/");
    localStorage.setItem("ip", res.data.ip);
  };

  useEffect(() => {
    if (location.code === "") {
      getCountry();
    }

    if (location.code === "zm" && location?.currency?.symbol === "ZK") {
      getCountry();
    }
    // if (!location?.profile?.country && location.code !== "") {
    //   dispatch(getLocaleProfile(location?.info?.code));
    // }
    dispatch(getLocaleProfile(location?.info?.code));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.code]);

  if (location.code !== "") {
    if (
      location.code === "ng" ||
      location.code === "gh" ||
      location.code === "zm" ||
      location.code === "cd"
    ) {
      defaultCountry = true;
    }
  }

  const isShowModal = () => {
    if (!loading && location.code === "" && !defaultCountry && !showModal) {
      dispatch(handleShowModal());
    }
  };

  useEffect(() => {
    if (localStorage.getItem("ip") === null) {
      getUserIp();
    }
  }, []);

  useEffect(() => {
    if (location.code === "drc") {
      i18n.changeLanguage("fr");
    }
  }, [location.code]);

  const setMedium = () => {
    if (searchParams.includes("utm_medium")) {
      localStorage.setItem("searchParams", searchParams);
    } else {
      localStorage.setItem(
        "searchParams",
        "?utm_medium=organic&utm_source=organic&utm_campaign=organic&utm_content=null&utm_id=null"
      );
    }
  };

  useEffect(() => {
    setMedium();
  }, []);

  return (
    <Container className="site-layout">
      <SelectCountryModal />
      <DisclaimerModal />
      {loading && location?.code !== "" && (
        <LoadingContainer>
          <Spin
            indicator={antIcon}
            style={{ color: "#000" }}
            size="large"
            tip="loading..."
          />
        </LoadingContainer>
      )}
      {<ContentBox>{children}</ContentBox>}
    </Container>
  );
};

export default LayoutView;

const Container = styled.div`
  background: #f0f2f5;
`;

export const ContentBox = styled.div`
  max-width: 425px;
  min-height: 100vh;
  background: #fff;
  margin: 0 auto;
  color: #fff;

  p {
    margin: 0;
  }
  .site-page-header {
    border: 1px solid rgb(235, 237, 240);
  }
  .menu-img {
    svg {
      colorr: #fff;
    }
  }
`;

export const LoadingContainer = styled.div`
  max-width: 425px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  background: #fff;
  align-items: center;
  justify-content: center;
`;

export const NotSupportedContainer = styled.div`
  max-width: 425px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: #fff;
  text-align: center;
  justify-content: center;
  padding: 1rem;

  p {
    text-align: center;
  }
`;

export const Logo = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
  img {
    width: ${(props) => (props.collapsed ? "50px" : "100px")};
    height: ${(props) => (props.collapsed ? "18px" : "35px")};
    margin-bottom: 20px;
  }
`;
