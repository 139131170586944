import { createSlice } from "@reduxjs/toolkit";
import { getUserDeposits } from "../../actions/transactions/wallet";

export const depositSlice = createSlice({
  name: "deposit",
  initialState: {
    activeDepositMethod: "",
    route: "",
    loading: false,
    success: false,
    deposits: null,
  },
  reducers: {
    handleActiveDepositMethod: (state, { payload }) => {
      state.activeDepositMethod = payload.channel;
      state.route = payload.route;
      return state;
    },
  },
  extraReducers: {
    [getUserDeposits.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [getUserDeposits.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.deposits = payload ?? [];
      return state;
    },
    [getUserDeposits.rejected]: (state) => {
      state.loading = false;
      state.success = true;
      state.deposits = [];
      return state;
    },
  },
});

export const { handleActiveDepositMethod } = depositSlice.actions;

export const depositSelector = (state) => state.deposit;
