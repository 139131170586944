import { createSlice } from "@reduxjs/toolkit";
import { getProfile } from "../../actions/profile";

export const profileSlice = createSlice({
  name: "profile",
  initialState: {
    loading: false,
    success: false,
    user: null,
  },
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.loading = false;
      state.user = null;
      return state;
    },
  },
  extraReducers: {
    [getProfile.pending]: (state) => {
      state.loading = true;
    },
    [getProfile.fulfilled]: (state, { payload }) => {
      state.user = payload;
      state.loading = false;
      state.success = true;
      return state;
    },
  },
});
export const { clearState } = profileSlice.actions;

export const profileSelector = (state) => state.profile;
