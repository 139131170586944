/* eslint-disable react/prop-types */
import { useSelector } from "react-redux";
import { Input } from "antd";
import styled from "styled-components";
import { getLocationSelector } from "../redux/reducers/getLocation";

const Inputfield = (props) => {
  const { location } = useSelector(getLocationSelector);

  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };

  return (
    <Container error={props.error}>
      {/* <select
          onChange={props.onValueChange}
          name={props.selectName}
          id={props.id}
        >
          {countryDialInfo.map((item, index) => {
            return (
              <option key={index} value={item.dial_code}>
                {item.flag} &nbsp;{item.dial_code}
              </option>
            );
          })}
        </select> */}
      <Input
        className="input"
        prefix={
          <>
            <img alt="flag" src={location?.info?.flag} width={30} />
            <span style={{ color: "rgba(42, 67, 75,0.5)" }}>
              {location?.info?.dial_code}
            </span>
          </>
        }
        name={props.name}
        type="number"
        inputMode="numeric"
        onInput={maxLengthCheck}
        placeholder={props.placeholder}
        onChange={props.onChange}
        onKeyPress={props.onKeyPress}
        value={props.value}
        readOnly={props.readOnly}
        maxLength="11"
        pattern="\d*"
        tabIndex={props.tabIndex}
      />
      {/* <input
          name={props.name}
          type="number"
          inputMode="numeric"
          onInput={maxLengthCheck}
          placeholder={props.placeholder}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          value={props.value}
          readOnly={props.readOnly}
          maxLength="11"
          pattern="\d*"
          tabIndex={props.tabIndex}
        /> */}
    </Container>
  );
};

export default Inputfield;

const Container = styled.div`
  width: 100%;
  border-radius: 4px;
  border: ${({ error }) =>
    error ? "1px solid #E15554" : "1px solid rgba(42, 67, 75, 0.15)"};

  /* .input {
      //   width: 90px;
      outline: none;
      border: none;
      background: transparent;
      height: 100%;
      padding: 0 6px 0 0;
      color: #999;
      font-size: 16px;
      font-weight: 400;
      // -webkit-appearance: none;
      // appearance: none;
    } */

  .input {
    outline: none;
    border: none;
    height: 100%;
    background: transparent;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.018em;
    color: #2a434b;
    width: 100%;
    letter-spacing: 0.06rem;
  }
  .input .ant-input {
    height: 1.8rem;
    font-size: 14px;
  }
  .input .ant-input-affix-wrapper {
    border-left: 0px;
  }
`;
