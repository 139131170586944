import { createAsyncThunk } from "@reduxjs/toolkit";

export const getProfile = createAsyncThunk("user/profile", async (thunkAPI) => {
  try {
    let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
    const { location } = JSON.parse(
      localStorage.getItem("persist:getLocation")
    );
    const code = JSON.parse(location).info.code;
    const rememberSession = localStorage.getItem("rememberSession");
    if (rememberSession) {
      tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
    }
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/user-profile`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenData?.accessToken}`,
        },
      }
    );
    let data = await response.json();
    if (data.status === "success") {
      return data.data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (err) {
    return thunkAPI.rejectWithValue({
      message: "Failed! Unable to complete your request.",
    });
  }
});

export const changePin = createAsyncThunk(
  "change/user/pin",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/auth/update-pin`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);

export const updateEmail = createAsyncThunk(
  "change/user/email",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/update-email`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);

export const verifyEmailOtp = createAsyncThunk(
  "verify/email/otp",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/verify-and-update-email`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);
