import { createSlice } from "@reduxjs/toolkit";
import {
  validateBankAccount,
  addBankAccount,
} from "../../actions/wallet/withdrawal";

export const addBankAccountSlice = createSlice({
  name: "addBankAccount",
  initialState: {
    validateLoading: false,
    validateSuccess: false,
    validateError: false,
    validateErrors: null,
    validatedName: "",
    loading: false,
    error: false,
    success: false,
    errors: null,
  },
  reducers: {
    clearValidateState: (state) => {
      state.validateSuccess = false;
      state.validateError = false;
      state.validateErrors = null;
      state.validateLoading = false;
      return state;
    },
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
  },
  extraReducers: {
    [validateBankAccount.pending]: (state) => {
      state.validateLoading = true;
      return state;
    },
    [validateBankAccount.fulfilled]: (state, { payload }) => {
      state.validateLoading = false;
      state.validateSuccess = true;
      state.validateError = false;
      state.validatedName = payload?.account_name;
      return state;
    },
    [validateBankAccount.rejected]: (state, { payload }) => {
      state.validateLoading = false;
      state.validateSuccess = false;
      state.validateError = true;
      state.validateErrors = payload.message;
      return state;
    },
    [addBankAccount.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [addBankAccount.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      return state;
    },
    [addBankAccount.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.errors = payload.message;
      return state;
    },
  },
});
export const { clearState, clearValidateState } =
  addBankAccountSlice.actions;
export const addBankAccountSelector = (state) => state.addBankAccount;
