import loadable from "@loadable/component";
import Preloader from "./preloader";

export const SignIn = loadable(() => import("../pages/auth/signin"), {
  LoadingComponent: Preloader,
});

export const SignUp = loadable(() => import("../pages/auth/signup"), {
  LoadingComponent: Preloader,
});

export const ForgotPassword = loadable(
  () => import("../pages/auth/forgotPassword"),
  {
    LoadingComponent: Preloader,
  }
);

export const ResetPassword = loadable(
  () => import("../pages/auth/resetPassword"),
  {
    LoadingComponent: Preloader,
  }
);

export const ErrorHandler = loadable(() => import("../pages/errorHandler"), {
  LoadingComponent: Preloader,
});

export const Home = loadable(() => import("../pages/home"), {
  LoadingComponent: Preloader,
});

export const Deposit = loadable(() => import("../pages/deposit"), {
  LoadingComponent: Preloader,
});

export const Withdrawal = loadable(() => import("../pages/withdrawal"), {
  LoadingComponent: Preloader,
});
export const HowToWithdraw = loadable(() => import("../pages/how/withdraw"), {
  LoadingComponent: Preloader,
});

export const HowToPlay = loadable(() => import("../pages/how/play"), {
  LoadingComponent: Preloader,
});

export const HowToDeposit = loadable(() => import("../pages/how/deposit"), {
  LoadingComponent: Preloader,
});

export const HowToPlayBingo90 = loadable(() => import("../pages/how/bingo90"), {
  LoadingComponent: Preloader,
});
export const HowToPlayBingo75 = loadable(() => import("../pages/how/bingo75"), {
  LoadingComponent: Preloader,
});
export const HowToPlayFastFive = loadable(() => import("../pages/how/fast5"), {
  LoadingComponent: Preloader,
});
export const Profile = loadable(() => import("../pages/profile"), {
  LoadingComponent: Preloader,
});
export const Bingo = loadable(() => import("../pages/bingo"), {
  LoadingComponent: Preloader,
});

export const Slots = loadable(() => import("../pages/slots"), {
  LoadingComponent: Preloader,
});

export const BuyTickets = loadable(() => import("../pages/buyTickets"), {
  LoadingComponent: Preloader,
});

export const Bingo90GamePlay = loadable(
  () => import("../pages/gameplay/bingo90"),
  {
    LoadingComponent: Preloader,
  }
);
export const Bingo30GamePlay = loadable(
  () => import("../pages/gameplay/bingo30"),
  {
    LoadingComponent: Preloader,
  }
);
export const Bingo36GamePlay = loadable(
  () => import("../pages/gameplay/Bingo36"),
  {
    LoadingComponent: Preloader,
  }
);
export const Bingo75GamePlay = loadable(
  () => import("../pages/gameplay/bingo75"),
  {
    LoadingComponent: Preloader,
  }
);
export const Fast5GamePlay = loadable(() => import("../pages/gameplay/fast5"), {
  LoadingComponent: Preloader,
});

export const CoinToss = loadable(() => import("../pages/gameplay/CoinToss"), {
  LoadingComponent: Preloader,
});

export const RollToWin = loadable(() => import("../pages/gameplay/RollToWin"), {
  LoadingComponent: Preloader,
});

export const SpinToWin = loadable(() => import("../pages/gameplay/SpinToWin"), {
  LoadingComponent: Preloader,
});

export const Bingo90Tickets = loadable(
  () => import("../pages/eventTickets/bingo90"),
  {
    LoadingComponent: Preloader,
  }
);

export const Bingo30Tickets = loadable(
  () => import("../pages/eventTickets/bingo30"),
  {
    LoadingComponent: Preloader,
  }
);

export const Bingo36Tickets = loadable(
  () => import("../pages/eventTickets/bingo36"),
  {
    LoadingComponent: Preloader,
  }
);

export const Bingo75Tickets = loadable(
  () => import("../pages/eventTickets/bingo75"),
  {
    LoadingComponent: Preloader,
  }
);

export const Fast5Tickets = loadable(
  () => import("../pages/eventTickets/fast5"),
  {
    LoadingComponent: Preloader,
  }
);
export const Details = loadable(() => import("../pages/details/details"), {
  LoadingComponent: Preloader,
});

export const LeaderBoard = loadable(() => import("../pages/leaderboard"), {
  LoadingComponent: Preloader,
});
export const underAge = loadable(() => import("../under18.jsx"), {
  LoadingComponent: Preloader,
});

export const jackpotLeadboard = loadable(() =>
  import("../pages/leaderboard/jackpot")
);
export const bingoLeadboard = loadable(() =>
  import("../pages/leaderboard/bingo")
);
export const dailyjpLeadboard = loadable(() =>
  import("../pages/leaderboard/dailyjp")
);

export const NewUserDeposit = loadable(() =>
  import("../pages/NewUserDeposit")
);
