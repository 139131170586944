import { createAsyncThunk } from "@reduxjs/toolkit";

export const getWallet = createAsyncThunk("get/wallet", async (thunkAPI) => {
  try {
    let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
    const { location } = JSON.parse(
      localStorage.getItem("persist:getLocation")
    );
    const code = JSON.parse(location).info.code;
    const rememberSession = localStorage.getItem("rememberSession");
    if (rememberSession) {
      tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
    }
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/all-wallets `,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenData?.accessToken}`,
        },
      }
    );
    let data = await response.json();
    if (data.status === "success") {
      return data.data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (err) {
    return thunkAPI.rejectWithValue([
      {
        message: "Failed! To establish internet connection.",
      },
    ]);
  }
});

// export const initializeFundWallet = createAsyncThunk(
//   "initialize/fund/wallet",
//   async (body, thunkAPI) => {
//     try {
//       let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
//       const location = localStorage.getItem("country_code");
//       const rememberSession = localStorage.getItem("rememberSession");
//       if (rememberSession) {
//         tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
//       }
//       const response = await fetch(
//         `${process.env.REACT_APP_BASE_URL}/${location}/${
//           process.env.REACT_APP_API_VERSION
//         }/user/wallet-deposit/${
//           location === "NG" ? "paystack" : "tola-mobile/mtn"
//         }`,
//         {
//           method: "POST",
//           headers: {
//             Accept: "application/json",
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${tokenData?.accessToken}`,
//           },
//           body: JSON.stringify(
//             location?.country_code === "NG"
//               ? { amount: body?.amount, email: body?.email }
//               : { amount: body?.amount, phone_num: body?.phone_num }
//           ),
//         }
//       );
//       let data = await response.json();
//       if (data.status === "success") {
//         return data.data;
//       } else {
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (err) {
//       return thunkAPI.rejectWithValue([
//         {
//           message: "Failed! To establish internet connection.",
//         },
//       ]);
//     }
//   }
// );
