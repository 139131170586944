import { createSlice } from "@reduxjs/toolkit";

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    expand: false,
    activeTab: "bingo",
    activeTransactionTab: "deposit",
    themeModal: false,
    activeColor: "#F6A20A",
    filterModal: false,
  },
  reducers: {
    handleExpand: (state) => {
      state.expand = !state.expand;
      return state;
    },
    handleActiveTab: (state, { payload }) => {
      state.activeTab = payload;
      return state;
    },
    handleActiveTransactionTab: (state, { payload }) => {
      state.activeTransactionTab = payload;
      return state;
    },
    handleActiveColor: (state, { payload }) => {
      state.activeColor = payload;
      return state;
    },
    handleShowThemeModal: (state) => {
      state.themeModal = !state.themeModal;
      return state;
    },
    handleShowFilterModal: (state) => {
      state.filterModal = !state.filterModal;
      return state;
    },
  },
});

export const {
  handleExpand,
  handleActiveTab,
  handleShowThemeModal,
  handleActiveColor,
  handleActiveTransactionTab,
  handleShowFilterModal,
} = settingsSlice.actions;

export const settingsSelector = (state) => state.settings;
