import { createSlice } from "@reduxjs/toolkit";
import { updateEmail } from "../../actions/profile";

export const updateEmailSlice = createSlice({
  name: "updateEmail",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
    otp_id: null,
  },
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
  },
  extraReducers: {
    [updateEmail.pending]: (state) => {
      state.loading = true;
    },
    [updateEmail.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.error = false;
      state.errors = null;
      state.loading = false;
      state.otp_id = payload.otp_id;
      return state;
    },
    [updateEmail.rejected]: (state, { payload }) => {
      state.errors = payload.message;
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});

export const { clearState } = updateEmailSlice.actions;

export const updateEmailSelector = (state) => state.updateEmail;
