import { createSlice } from "@reduxjs/toolkit";
import { verifyOtp } from "../../../actions/auth/forgotPassword";

export const verifyResetOtpSlice = createSlice({
  name: "verifyResetOtp",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
  },
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
  },
  extraReducers: {
    [verifyOtp.pending]: (state) => {
      state.loading = true;
    },
    [verifyOtp.fulfilled]: (state) => {
      state.success = true;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
    [verifyOtp.rejected]: (state, { payload }) => {
      state.errors = payload.message;
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});
export const { clearState } = verifyResetOtpSlice.actions;

export const verifyResetOtpSelector = (state) => state.verifyResetOtp;
