export const countryDialInfo = [
  {
    name: "Nigeria",
    flag: "https://flagcdn.com/ng.svg",
    code: "ng",
    country_code: "ng",
    dial_code: "+234",
  },
  {
    name: "Ghana",
    flag: "https://flagcdn.com/gh.svg",
    code: "gh",
    country_code: "gh",
    dial_code: "+233",
  },
  {
    name: "Kenya",
    flag: "https://flagcdn.com/ke.svg",
    code: "ke",
    country_code: "ke",
    dial_code: "+254",
  },
  {
    name: "Congo(DRC)",
    flag: "https://flagcdn.com/cd.svg",
    code: "cd",
    country_code: "drc",
    dial_code: "+243",
  },
  {
    name: "Malawi",
    flag: "https://flagcdn.com/mw.svg",
    code: "mw",
    country_code: "mw",
    dial_code: "+265",
  },
  {
    name: "Uganda",
    flag: "https://flagcdn.com/ug.svg",
    code: "ug",
    country_code: "ug",
    dial_code: "+256",
  },
  {
    name: "Tanzania",
    flag: "https://flagcdn.com/tz.svg",
    code: "tz",
    country_code: "tz",
    dial_code: "+255",
  },
  {
    name: "Zambia",
    flag: "https://flagcdn.com/zm.svg",
    code: "zm",
    country_code: "zm",
    dial_code: "+260",
  },
];
