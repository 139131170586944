import { createSlice } from "@reduxjs/toolkit";
import { initializeFundWalletNg, initializeFundWalletNgQuickteller } from "../../actions/fundWallet";


export const fundWalletNgSlice = createSlice({
  name: "fundWalletNg",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
    redirect_url: null,
    merchant_code: null,
    pay_item_id: null,
    txn_ref: null,
    cust_name: null,
    cust_id: null,
    currencies: null,
    site_redirect_url: null,
    form_action: null,
    cust_email: null,
    quickAmount: null
  },
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
  },
  extraReducers: {
    [initializeFundWalletNg.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [initializeFundWalletNg.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.redirect_url = payload.url;
      return state;
    },
    [initializeFundWalletNg.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.errors = payload.message;
      return state;
    },
    [initializeFundWalletNgQuickteller.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [initializeFundWalletNgQuickteller.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.redirect_url = null;
      state.merchant_code = payload.merchant_code;
      state.pay_item_id = payload.pay_item_id;
      state.txn_ref = payload.txn_ref;
      state.cust_name = payload.cust_email;
      state.cust_id = payload.cust_id;
      state.currencies = payload.currency;
      state.site_redirect_url = payload.site_redirect_url;
      state.form_action = payload.form_action;
      state.cust_email = payload.cust_name;
      state.quickAmount = payload.amount;
      return state;
    },
    [initializeFundWalletNgQuickteller.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.errors = payload.message;
      return state;
    },
  },
});
export const { clearState } = fundWalletNgSlice.actions;
export const fundWalletNgSelector = (state) => state.fundWalletNg;
