import { createAsyncThunk } from "@reduxjs/toolkit";

export const registerUser = createAsyncThunk(
  "auth/user/register",
  async (body, thunkAPI) => {
    try {
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const ref = localStorage.getItem("ref") || "";

      const searchParams = localStorage.getItem("searchParams");
      const params = new URLSearchParams(searchParams);
      const utm_source = params.get("utm_source");
      const utm_medium = params.get("utm_medium");
      const utm_campaign = params.get("utm_campaign");
      const utm_id = params.get("utm_id");

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/auth/register?utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}&utm_id=${utm_id}&ref=${ref}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        sessionStorage.setItem("signUpData", JSON.stringify(data.data));
        localStorage.removeItem("ref");
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);

export const verifyRegisterUser = createAsyncThunk(
  "auth/register/verify",
  async (body, thunkAPI) => {
    try {
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/auth/verify-otp`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);

export const setUserPin = createAsyncThunk(
  "auth/register/setPin",
  async (body, thunkAPI) => {
    try {
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/auth/set-login-pin`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        sessionStorage.removeItem("signUpData");
        sessionStorage.setItem(
          "betty_tkd",
          JSON.stringify({
            accessToken: data?.data?.token,
            expiresIn: data?.data?.expiresIn,
          })
        );
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);

export const getVerifyPhoneOtp = createAsyncThunk(
  "get/verification/otp",
  async (phone, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/auth/resend-otp`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(phone),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);
