import { Route, Switch, withRouter } from "react-router-dom";
import * as Pages from "./pageloader";
import Layout from "./pages/Layout";
import PrivateRoute from "./pages/PrivateRoute";
import "antd/dist/antd.min.css";
import "react-loading-skeleton/dist/skeleton.css";
import "react-toastify/dist/ReactToastify.css";
import "./app.less";
import "./App.scss";
import { getProfile } from "./redux/actions/profile";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, Suspense, useState } from "react";
import Preloader from "./pageloader/preloader";
// import PagesMaintenance from "./pages-maintenance";
import { profileSelector } from "./redux/reducers/profile/profile";
import { isFriday } from "./utilities/isFriday";
import { getLocationSelector } from "./redux/reducers/getLocation";
import PagesMaintenance from "./pages-maintenance";

const App = ({ history }) => {
  const dispatch = useDispatch();

  const { location } = useSelector(getLocationSelector);
  let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
  const rememberSession = localStorage.getItem("rememberSession");
  if (rememberSession) {
    tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
  }

  const { pathname } = history.location;
  const { user } = useSelector(profileSelector);
  const userId = user?.user_id;

  let isNoDeposit = false;
  if (user !== null && user?.deposits_count === 0) {
    isNoDeposit = true;
  }


  useEffect(() => {
    window.analytics.setAnonymousId(userId);
    window.analytics.page();
  }, [pathname, userId]);

  useEffect(() => {
    if (tokenData) {
      dispatch(getProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const isMaintenanceHappening = process.env.REACT_APP_IS_MAINTENANCE_HAPPENING;

  if (isMaintenanceHappening === "true") {
    return (
      <Suspense fallback={<Preloader />}>
        <Switch>
          <Layout>
            <Route exact path="/" component={PagesMaintenance} />
          </Layout>
        </Switch>
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<Preloader />}>
      {/* {location?.code === "gh" ? (
        <Layout>
          <Modal
            width={380}
            visible
            footer={null}
            title={<h6>Betting and Payments Paused Temporarily</h6>}
          >
            <div style={{ fontSize: "14px" }}>
              Dear BettyBingo Player, In line with the new regulatory
              guidelines, we are making improvements to ensure we offer you the
              best experience. Your funds are safe, and active bets will be
              settled usual. We do apologise for the inconvenience and aim to
              resume offering you the best services as soon as possible.
            </div>
          </Modal>
        </Layout>
      ) : ( */}
      <Switch>
        <Layout>
          {(isNoDeposit && location?.code === "ng") ||
          (isNoDeposit && location?.code === "zm") ? (
            <Route exact path="/" component={Pages.NewUserDeposit} />
          ) : (
            <Route
              exact
              path="/"
              component={Pages.Bingo}
              // component={isFriday() ? Pages.Bingo : Pages.Slots}
            />
          )}
          <Route path="/register" component={Pages.SignUp} />
          <Route exact path="/login" component={Pages.SignIn} />
          <Route path="/forgot-password" component={Pages.ForgotPassword} />
          <Route exact path="/reset-password" component={Pages.ResetPassword} />
          <Route path="/bingo" component={Pages.Bingo} />
          <Route path="/games" component={Pages.Slots} />
          <Route exact path="/deposit" component={Pages.Deposit} />
          <Route exact path="/deposit/success" component={Pages.Deposit} />
          <Route exact path="/winners" component={Pages.LeaderBoard} />
          <Route
            exact
            path="/winners/jackpot"
            component={Pages.jackpotLeadboard}
          />
          <Route exact path="/winners/bingo" component={Pages.bingoLeadboard} />
          <Route
            exact
            path="/winners/dailyjp"
            component={Pages.dailyjpLeadboard}
          />
          <Route exact path="/underage" component={Pages.underAge} />
          <Route exact path="/how/deposit" component={Pages.HowToDeposit} />
          <Route exact path="/how/play" component={Pages.HowToPlay} />
          <Route exact path="/how/withdraw" component={Pages.HowToWithdraw} />
          <Route
            exact
            path="/how/play/bingo-90"
            component={Pages.HowToPlayBingo90}
          />
          <Route
            exact
            path="/how/play/bingo-75"
            component={Pages.HowToPlayBingo75}
          />
          <Route
            exact
            path="/how/play/fast-5"
            component={Pages.HowToPlayFastFive}
          />
          {/* Private routes */}
          <Route path="/buy-tickets" component={Pages.BuyTickets} />
          <PrivateRoute path="/withdrawal" component={Pages.Withdrawal} />
          <PrivateRoute path="/profile" component={Pages.Profile} />
          <PrivateRoute path="/game/details" component={Pages.Details} />
          <Route path="/gameplay/bingo90" component={Pages.Bingo90GamePlay} />
          <Route path="/gameplay/bingo75" component={Pages.Bingo75GamePlay} />
          <Route path="/gameplay/bingo30" component={Pages.Bingo30GamePlay} />
          <Route path="/gameplay/bingo36" component={Pages.Bingo36GamePlay} />
          <Route path="/gameplay/fast5" component={Pages.Fast5GamePlay} />
          {/* <Route path="/gameplay/cointoss" component={Pages.CoinToss} /> */}
          <Route path="/gameplay/rolltowin" component={Pages.RollToWin} />
          <Route path="/gameplay/spinthebottle" component={Pages.SpinToWin} />
          <Route
            path="/game-tickets/bingo90"
            component={Pages.Bingo90Tickets}
          />
          <Route
            path="/game-tickets/bingo75"
            component={Pages.Bingo75Tickets}
          />
          <Route
            path="/game-tickets/bingo30"
            component={Pages.Bingo30Tickets}
          />
          <Route
            path="/game-tickets/bingo36"
            component={Pages.Bingo36Tickets}
          />
          <Route path="/game-tickets/fast5" component={Pages.Fast5Tickets} />
          <Route path="/new-user/deposit" component={Pages.NewUserDeposit} />
          {/* 
          <Route path="*">
            <Redirect to={Pages.Home} />
          </Route> */}
        </Layout>
      </Switch>

      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Suspense>
  );
};

export default withRouter(App);
