import { createAsyncThunk } from "@reduxjs/toolkit";
import { defaultCountries } from "../../../pages/Layout/modal";

export const loginUser = createAsyncThunk(
  "auth/user/login",
  async (body, thunkAPI) => {
    try {
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      // const { utm_source, utm_campaign, utm_medium } = body;
      const searchParams = localStorage.getItem("searchParams");
      const params = new URLSearchParams(searchParams);
      const utm_source = params.get("utm_source");
      const utm_medium = params.get("utm_medium");
      const utm_campaign = params.get("utm_campaign");
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/auth/login?utm_source=${utm_source}&utm_campaign=${utm_campaign}&utm_medium=${utm_medium}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();

      if (data.status === "success") {
        rememberSession
          ? localStorage.setItem(
              "betty_tkd",
              JSON.stringify({
                accessToken: data?.data?.token,
                expiresIn: data?.data?.expiresIn,
              })
            )
          : sessionStorage.setItem(
              "betty_tkd",
              JSON.stringify({
                accessToken: data?.data?.token,
                expiresIn: data?.data?.expiresIn,
              })
            );
        const localeProfile = data?.data?.locale_country_profile?.tld_code;
        const profile = defaultCountries.filter(
          (country) => country.code === localeProfile
        );
        if (profile && profile.length > 0) {
          localStorage.setItem("country_code", profile[0]?.country_code);
          localStorage.setItem(
            "currency",
            JSON.stringify(profile[0]?.currency)
          );
        }
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      console.log(err);
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);
