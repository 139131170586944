import { createSlice } from "@reduxjs/toolkit";
import {
  getUserWithdrawals,
  cancelUserWithdrawal,
  getUserWithdrawalOption,
} from "../../actions/transactions/wallet";

export const getWithdrawalsSlice = createSlice({
  name: "getWithdrawals",
  initialState: {
    cancelWithdrawalError: false,
    cancelWithdrawalLoading: false,
    cancelWithdrawalSuccess: false,
    canceledWithdrawal: null,
    loading: false,
    success: false,
    withdrawals: [],
    withdrawalOptions: [],
  },
  reducers: {
    clearCancelWithdrawalState: (state) => {
      state.cancelWithdrawalError = false;
      state.cancelWithdrawalLoading = false;
      state.cancelWithdrawalSuccess = false;
      state.canceledWithdrawal = null;
      state.withdrawalOptions = [];
      return state;
    },
  },
  extraReducers: {
    [getUserWithdrawals.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [getUserWithdrawals.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.withdrawals = payload;
      return state;
    },
    [getUserWithdrawals.rejected]: (state) => {
      state.loading = false;
      state.success = true;
      state.withdrawals = [];
      return state;
    },
    [getUserWithdrawalOption.fulfilled]: (state, { payload }) => {
      state.withdrawalOptions = payload[0];
      return state;
    },
    [getUserWithdrawalOption.rejected]: (state) => {
      state.withdrawalOptions = [];
      return state;
    },
    [cancelUserWithdrawal.pending]: (state) => {
      state.cancelWithdrawalLoading = true;
      return state;
    },
    [cancelUserWithdrawal.fulfilled]: (state, { payload }) => {
      state.cancelWithdrawalLoading = false;
      state.cancelWithdrawalSuccess = true;
      state.canceledWithdrawal = payload;
      return state;
    },
    [cancelUserWithdrawal.rejected]: (state) => {
      state.cancelWithdrawalLoading = false;
      state.cancelWithdrawalSuccess = true;
      state.canceledWithdrawal = {};
      return state;
    },
  },
});

export const { clearCancelWithdrawalState } = getWithdrawalsSlice.actions;

export const getWithdrawalsSelector = (state) => state.getWithdrawals;
