import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button } from "./index";
import styled from "styled-components";
import { BettyArrowLeft, UserAvatar } from "../assets/icons";
import { profileSelector } from "../redux/reducers/profile/profile";
import { Routes } from "../utilities/constants";
import { Tooltip } from "antd";

const SecondaryHeader = (props) => {
  const history = useHistory();
  const { user } = useSelector(profileSelector);
  let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
  const rememberSession = localStorage.getItem("rememberSession");
  if (rememberSession) {
    tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
  }

  const goBack = () => {
    if (props?.routeTo === "home") {
      history.push(Routes.home);
    } else {
      if (history.action !== "POP") {
        history.goBack();
      } else {
        history.goBack();
      }
    }
  };

  return (
    <Container>
      <div className="element_group">
        <BettyArrowLeft onClick={goBack} fill="#287D72" className="icon" />
        <h3>{props.text}</h3>
      </div>
      {user && tokenData && (
        <div className="action_keys">
          {props.showPhone && <Button full secondary text={user?.phone_num} />}
          <Tooltip title="Go To Your Profile" color="#f6a20a">
          <Link to="/profile">
            <img src={UserAvatar} alt="inbox" />
          </Link>
          </Tooltip>
        </div>
      )}
    </Container>
  );
};

export default SecondaryHeader;

const Container = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px 16px;
  height: 57px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);

  .element_group {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  h3 {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 19px;
    line-height: 120%;
    color: #287d72;
    margin: 0;
    padding: 0;
  }

  .action_keys{
    display:flex;
  }
  button {
    width: 77.5px;
    height: 28px;
    font-size: 11px;
    padding: 10px;
    border-radius: 100px;
    margin-right: 0.5rem;
  }

  .icon {
    cursor: pointer;

    :hover {
      transform: scale(1.1);
      opacity: 0.8;
    }
  }
`;
