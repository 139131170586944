import { createSlice } from "@reduxjs/toolkit";
import {
  getBankAccounts,
  verifyUserWithdrawal,
  getWithdrawalMailOtp,
  createWithdrawal,
} from "../../actions/wallet/withdrawal";

export const withdrawalSlice = createSlice({
  name: "withdrawal",
  initialState: {
    getBankLoading: false,
    getBankSuccess: false,
    getBankError: false,
    getBankErrors: null,
    accounts: [],
    loading: false,
    error: false,
    success: false,
    errors: null,
    getOtpLoading: false,
    getOtpError: false,
    getOtpErrors: null,
    getOtpSuccess: false,
    withdrawalDetails: {
      amount: "",
      withdrawal_options_id: "",
      otp_id: "",
      token:""
    },
  },
  reducers: {
    clearGetBankState: (state) => {
      state.getBankSuccess = false;
      state.getBankError = false;
      state.getBankErrors = null;
      state.getBankLoading = false;
      return state;
    },
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
    clearOTPState: (state) => {
      state.getOtpSuccess = true;
      state.getOtpError = false;
      state.getOtpErrors = null;
      state.getOtpLoading = false;
      state.withdrawalDetails = {
        amount: "",
        withdrawal_options_id: "",
        otp_id: "",
      };
      return state;
    },
    resetOTPState: (state) => {
      state.getOtpSuccess = false;
      state.getOtpError = false;
      state.getOtpErrors = null;
      state.getOtpLoading = false;
      return state;
    },
    handleSetWithdrawalDetails: (state, { payload }) => {
      state.withdrawalDetails.amount = payload.amount;
      state.withdrawalDetails.withdrawal_options_id =
        payload.withdrawal_options_id;
        state.withdrawalDetails.token = payload.token
      return state;
    },
  },
  extraReducers: {
    [getBankAccounts.pending]: (state) => {
      state.getBankLoading = true;
      return state;
    },
    [getBankAccounts.fulfilled]: (state, { payload }) => {
      state.getBankLoading = false;
      state.getBankSuccess = true;
      state.getBankError = false;
      state.accounts = payload;
      return state;
    },
    [getBankAccounts.rejected]: (state, { payload }) => {
      state.getBankLoading = false;
      state.getBankSuccess = false;
      state.getBankError = true;
      state.getBankErrors = payload.message;
      return state;
    },
    [verifyUserWithdrawal.pending]: (state) => {
      state.getOtpLoading = true;
      return state;
    },
    [getWithdrawalMailOtp.pending]: (state) => {
      state.getOtpLoading = true;
      return state;
    },
    [verifyUserWithdrawal.fulfilled]: (state, { payload }) => {
      state.getOtpLoading = false;
      state.getOtpSuccess = true;
      state.getOtpError = false;
      state.withdrawalDetails.otp_id = payload.otp_response.otp_id;
      return state;
    },
    [getWithdrawalMailOtp.fulfilled]: (state, { payload }) => {
      state.getOtpLoading = false;
      state.getOtpSuccess = true;
      state.getOtpError = false;
      state.withdrawalDetails.otp_id = payload.otp_id;
      return state;
    },
    [verifyUserWithdrawal.rejected]: (state, { payload }) => {
      state.getOtpLoading = false;
      state.getOtpSuccess = true;
      state.getOtpError = true;
      state.getOtpErrors = payload.message;
      return state;
    },
    [getWithdrawalMailOtp.rejected]: (state, { payload }) => {
      state.getOtpLoading = false;
      state.getOtpSuccess = true;
      state.getOtpError = true;
      state.getOtpErrors = payload.message;
      return state;
    },
    [createWithdrawal.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [createWithdrawal.fulfilled]: (state) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      return state;
    },
    [createWithdrawal.rejected]: (state, { payload }) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      state.errors = payload.message;
      return state;
    },
  },
});
export const {
  clearState,
  clearGetBankState,
  clearOTPState,
  handleSetWithdrawalDetails,
  resetOTPState,
} = withdrawalSlice.actions;
export const withdrawalSelector = (state) => state.withdrawal;
