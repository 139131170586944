import { createAsyncThunk } from "@reduxjs/toolkit";

export const getLocation = createAsyncThunk(
  "get/location",
  async (thunkAPI) => {
    try {
      const response = await fetch(
        `https://api.ipstack.com/check?access_key=${process.env.REACT_APP_IPSTACK_ACCESS_KEY}&format=1`,
        {
          method: "GET",
        }
      );
      let data = await response.json();

      if (data?.country_code !== null) {
        // if (
        //   data?.country_code === "NG" ||
        //   data?.country_code === "GH" ||
        //   data?.country_code === "CD" ||
        //   data?.country_code === "ZM"
        // ) {
        //   localStorage.setItem("currency", JSON.stringify(data?.currency));
        //   localStorage.setItem(
        //     "country_code",
        //     data?.country_code?.toLowerCase()
        //   );
        // }
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);

export const getLocaleProfile = createAsyncThunk(
  "get/locale",
  async (body, thunkAPI) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${body}/v1/locale/profile`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);
