import { createAsyncThunk } from "@reduxjs/toolkit";

export const getBingoHistory = createAsyncThunk(
  "get/bingo/history",
  async (thunkAPI) => {
    try {
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_COMMERCE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/ticket/user-bingo-history`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);
