import { createSlice } from "@reduxjs/toolkit";
import { registerUser } from "../../../actions/auth/signup";

export const signupSlice = createSlice({
  name: "signup",
  initialState: {
    data: {
      gender: "",
    },
    loading: false,
    success: false,
    error: false,
    errors: null,
  },
  reducers: {
    handleSelectGender: (state, { payload }) => {
      state.data.gender = payload;
      return state;
    },
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
  },
  extraReducers: {
    [registerUser.pending]: (state) => {
      state.loading = true;
    },
    [registerUser.fulfilled]: (state) => {
      state.success = true;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
    [registerUser.rejected]: (state, { payload }) => {
      state.errors = payload.message;
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});

export const { handleSelectGender, clearState } = signupSlice.actions;

export const signupSelector = (state) => state.signup;
