import { createSlice } from "@reduxjs/toolkit";
import { getWallet } from "../../actions/wallet/deposit";

export const getWalletSlice = createSlice({
  name: "getWallet",
  initialState: {
    loading: false,
    success: false,
    error: false,
    wallet_data: null,
  },
  extraReducers: {
    [getWallet.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [getWallet.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      state.wallet_data = payload;
      return state;
    },
    [getWallet.rejected]: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      return state;
    },
  },
});

export const getWalletSelector = (state) => state.getWallet;
