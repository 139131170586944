import { createSlice } from "@reduxjs/toolkit";
import { getNewGames} from "../../actions/slot/getGames";

export const getNewGamesSlice = createSlice({
  name: "getNewGames",
  initialState: {
    games: null,
    loading: false,
    success: false,
    error: false,
    errors: null,
    pagination: null,
  },
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
  },
  extraReducers: {
    [getNewGames.pending]: (state) => {
      state.loading = true;
    },
    [getNewGames.fulfilled]: (state, { payload }) => {
      state.success = true;
     
      state.games = payload;
      state.error = false;
      state.errors = null;
      state.loading = false;
      state.pagination = payload?.pagination;
      return state;
    },
    [getNewGames.rejected]: (state, { payload }) => {
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});

export const { clearState } = getNewGamesSlice.actions;

export const getNewGamesSelector = (state) => state.getNewGames;
