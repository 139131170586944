import { createAsyncThunk } from "@reduxjs/toolkit";

export const signupSegmentAnalytics = createAsyncThunk(
  "/signup/analytics/segment",
  async ({ userId, params }, thunkAPI) => {
    try {
      // get user location
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/analytics/segment?event=signup&userId=${userId}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      const utm_source = params["utm_source"];
      const utm_medium = params["utm_medium"];
      const utm_campaign = params["utm_campaign"];

      let data = await response.json();
      if (data.status === "success") {
        const analyticsData = data.data;
        const analyticsType = analyticsData.type;
        const event = analyticsData.event;
        const properties = analyticsData.properties;
        if (analyticsType === "identify_track") {
          // identify
          if (Object.keys(properties.identify_data).length > 0) {
            window.analytics.identify(userId, {
              ...properties.identify_data,
              source: utm_source,
              medium: utm_medium,
              campaign: utm_campaign,
            });
          }

          // track
          if (Object.keys(properties.track_data).length > 0) {
            window.analytics.track(event, {
              ...analyticsData.track_data,
              country: properties.track_data.country,
              source: properties.track_data.source || utm_source,
              medium: properties.meduim || utm_medium,
              campaign: properties.track_data.campaign || utm_campaign,
              uuid: properties.track_data.uuid || userId,
              created: new Date().toLocaleString(),
            });
          }
        }

        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);
