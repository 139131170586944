import React from "react";
import styled from "styled-components";

const OTPInputfield = (props) => {
  return (
    <Container
      error={props.error}
      name={props.name}
      value={props.value}
      onChange={props.onChange}
      onKeyUp={props.onKeyUp}
      pattern="\d*"
      // inputmode="tel"
      type="password"
      min="0"
      max="9"
      autoComplete="off"
      tabIndex={props.tabIndex}
      maxLength="1"
      autoFocus={true}
    />
  );
};

export default OTPInputfield;

const Container = styled.input`
  height: 48px;
  width: 100%;
  background: transparent;
  border: ${({ error }) =>
    error ? "1px solid #E15554" : "1px solid rgba(42, 67, 75, 0.15)"};
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 2em;
  color: #2a434b;
  text-align: center;

  @media screen and (max-width: 450px) {
    font-size: 2em;
  }

  :focus {
    outline: 4px solid #f6a20a0e;
    border: 1px solid #fce3b6;
  }

  :hover {
    border: 1px solid #fce3b6;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;
