// Circular loading Icon
import { Loading3QuartersOutlined } from "@ant-design/icons";
import {
  AfricellLogo,
  AirtelLogo,
  AirteltigoLogo,
  MTNLogo,
  OrangeLogo,
  VodacomLogo,
  VodaphoneLogo,
  ZamtelLogo,
} from "../assets/images";

import { GhRoomIcon, ZmRoomIcon, DrcRoomIcon } from "../assets/icons";

export const antIcon = (
  <Loading3QuartersOutlined style={{ fontSize: 36, color: "#000" }} spin />
);

export const timeout = async (value) => {
  await new Promise((res) => setTimeout(res, value));
};

export const Routes = {
  home: "/",
  signUp: "/register",
  signUpVerification: "/register/verification",
  signUpSetPin: "/register/setPin",
  signIn: "/login",
  phoneVerification: "/register/verifyPhone",
  forgotPassword: "/forgot-password",
  forgotPasswordVerification: "/forgot-password/verification",
  forgotPasswordResetPin: "/forgot-password/resetPin",
  profile: "/profile",
  profilePersonalSettings: "/profile/personalSettings",
  profileHistory: "/profile/history",
  profileGameplay: "/profile/gameplay",
  profileReferrals: "/profile/referrals",
  profileUpdateEmail: "/profile/update-email",
  profileDaubing: "/profile/daubingShape",
  profileWallet: "/profile/wallet",
  deposit: "/deposit",
  depositSuccess: "/deposit/success",
  withdrawal: "/withdrawal",
  withdrawalAddNew: "/withdrawal/addNew",
  withdrawalSuccess: "/withdrawal/success",
  slotMain: "/games",
  slotsTop: "/games/top",
  slotsPopular: "/games/popular",
  slotsRecommended: "/games/recommended",
  help: "/help",
  privacy: "/profile/privacy-policy",
  vipTerms: "/profile/vip-terms",
  redeem: "/profile/redeem-bonus",
};

export const yearOfBirth = (startYear) => {
  let currentYear = new Date().getFullYear(),
    years = [];
  startYear = startYear || 1940;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

export const isEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
};

export const dateDiff = (date1, date2) => {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);

  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
};

export const dayOfBirth = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

export const monthOfBirth = [
  { month: "Jan", value: "01" },
  { month: "Feb", value: "02" },
  { month: "Mar", value: "03" },
  { month: "Apr", value: "04" },
  { month: "May", value: "05" },
  { month: "Jun", value: "06" },
  { month: "Jul", value: "07" },
  { month: "Aug", value: "08" },
  { month: "Sep", value: "09" },
  { month: "Oct", value: "10" },
  { month: "Nov", value: "11" },
  { month: "Dec", value: "12" },
];

export const nigeriaBankList = [
  {
    name: "Access Bank",
    slug: "access-bank",
    code: "044",
    ussd: "*901#",
  },
  {
    name: "Access Bank (Diamond)",
    slug: "access-bank-diamond",
    code: "063",
    ussd: "*426#",
  },
  {
    name: "ALAT by WEMA",
    slug: "alat-by-wema",
    code: "035A",
    ussd: "*945*100#",
  },
  {
    name: "ASO Savings and Loans",
    slug: "asosavings",
    code: "401",
    ussd: "",
  },
  {
    name: "Bowen Microfinance Bank",
    slug: "bowen-microfinance-bank",
    code: "50931",
    ussd: "",
  },
  {
    name: "CEMCS Microfinance Bank",
    slug: "cemcs-microfinance-bank",
    code: "50823",
    ussd: "",
  },
  {
    name: "Citibank Nigeria",
    slug: "citibank-nigeria",
    code: "023",
    ussd: "",
  },
  {
    name: "Ecobank Nigeria",
    slug: "ecobank-nigeria",
    code: "050",
    ussd: "*326#",
  },
  {
    name: "Ekondo Microfinance Bank",
    slug: "ekondo-microfinance-bank",
    code: "562",
    ussd: "*540*178#",
  },
  {
    name: "Fidelity Bank",
    slug: "fidelity-bank",
    code: "070",
    ussd: "*770#",
  },
  {
    name: "First Bank of Nigeria",
    slug: "first-bank-of-nigeria",
    code: "011",
    ussd: "*894#",
  },
  {
    name: "First City Monument Bank",
    slug: "first-city-monument-bank",
    code: "214",
    ussd: "*329#",
  },
  {
    name: "Globus Bank",
    slug: "globus-bank",
    code: "00103",
    ussd: "*989#",
  },
  {
    name: "Guaranty Trust Bank",
    slug: "guaranty-trust-bank",
    code: "058",
    ussd: "*737#",
  },
  {
    name: "Hasal Microfinance Bank",
    slug: "hasal-microfinance-bank",
    code: "50383",
    ussd: "*322*127#",
  },
  {
    name: "Heritage Bank",
    slug: "heritage-bank",
    code: "030",
    ussd: "*322#",
  },
  {
    name: "Jaiz Bank",
    slug: "jaiz-bank",
    code: "301",
    ussd: "*389*301#",
  },
  {
    name: "Keystone Bank",
    slug: "keystone-bank",
    code: "082",
    ussd: "*7111#",
  },
  {
    name: "Kuda Bank",
    slug: "kuda-bank",
    code: "50211",
    ussd: "",
  },
  {
    name: "One Finance",
    slug: "one-finance",
    code: "565",
    ussd: "*1303#",
  },
  {
    name: "Paga",
    slug: "paga",
    code: "327",
    ussd: "",
  },
  {
    name: "Parallex Bank",
    slug: "parallex-bank",
    code: "526",
    ussd: "*322*318*0#",
  },
  {
    name: "PayCom",
    slug: "paycom",
    code: "100004",
    ussd: "*955#",
  },
  {
    name: "Polaris Bank",
    slug: "polaris-bank",
    code: "076",
    ussd: "*833#",
  },
  {
    name: "Providus Bank",
    slug: "providus-bank",
    code: "101",
    ussd: "",
  },
  {
    name: "Rubies MFB",
    slug: "rubies-mfb",
    code: "125",
    ussd: "*7797#",
  },
  {
    name: "Sparkle Microfinance Bank",
    slug: "sparkle-microfinance-bank",
    code: "51310",
    ussd: "",
  },
  {
    name: "Stanbic IBTC Bank",
    slug: "stanbic-ibtc-bank",
    code: "221",
    ussd: "*909#",
  },
  {
    name: "Standard Chartered Bank",
    slug: "standard-chartered-bank",
    code: "068",
    ussd: "",
  },
  {
    name: "Sterling Bank",
    slug: "sterling-bank",
    code: "232",
    ussd: "*822#",
  },
  {
    name: "Suntrust Bank",
    slug: "suntrust-bank",
    code: "100",
    ussd: "*5230#",
  },
  {
    name: "TAJ Bank",
    slug: "taj-bank",
    code: "302",
    ussd: "*898#",
  },
  {
    name: "TCF MFB",
    slug: "tcf-mfb",
    code: "51211",
    ussd: "*908#",
  },
  {
    name: "Titan Trust Bank",
    slug: "titan-trust-bank",
    code: "102",
    ussd: "*922#",
  },
  {
    name: "Union Bank of Nigeria",
    slug: "union-bank-of-nigeria",
    code: "032",
    ussd: "*826#",
  },
  {
    name: "United Bank For Africa",
    slug: "united-bank-for-africa",
    code: "033",
    ussd: "*919#",
  },
  {
    name: "Unity Bank",
    slug: "unity-bank",
    code: "215",
    ussd: "*7799#",
  },
  {
    name: "VFD",
    slug: "vfd",
    code: "566",
    ussd: "",
  },
  {
    name: "Wema Bank",
    slug: "wema-bank",
    code: "035",
    ussd: "*945#",
  },
  {
    name: "Zenith Bank",
    slug: "zenith-bank",
    code: "057",
    ussd: "*966#",
  },
];

export const ghIdType = [
  "DRIVERS_LICENSE",
  "NEW_VOTER_ID",
  "PASSPORT",
  "SSNIT",
];
export const ngIdType = ["VNIN", "BVN", "PHONE_NUMBER"];

export const notifWords = [
  "Pow!",
  "Bang!",
  "Letgo!",
  "Come on!",
  "Wow!",
  "Omo!",
];

export const ghOperators = [
  { name: "MTN", icon: MTNLogo },
  { name: "Airtel", icon: AirteltigoLogo },
  { name: "Vodafone", icon: VodaphoneLogo },
];
export const zmOperators = [
  { name: "MTN", icon: MTNLogo },
  { name: "Airtel", icon: AirtelLogo },
  { name: "Zamtel", icon: ZamtelLogo },
];
export const drcOperators = [
  { name: "Airtel", icon: AirtelLogo },
  { name: "Vodacom", icon: VodacomLogo },
  { name: "Africell", icon: AfricellLogo },
  { name: "Orange", icon: OrangeLogo },
];
export const ghRoom = {
  name: "Ghana Only",
  icon: <GhRoomIcon style={{ opacity: 1 }} />,
};
export const zmRoom = {
  name: "Zambia Only",
  icon: <ZmRoomIcon style={{ opacity: 1 }} />,
};
export const drcRoom = {
  name: "DRC Only",
  icon: <DrcRoomIcon style={{ opacity: 1 }} />,
};
