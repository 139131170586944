import { createAsyncThunk } from "@reduxjs/toolkit";

export const getOtp = createAsyncThunk(
  "forgotpassword/getOtp",
  async (body, thunkAPI) => {
    try {
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/auth/forgot-pin`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        sessionStorage.setItem("otp_id", data.data.otp_id);
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "forgotpassword/verifyOtp",
  async (body, thunkAPI) => {
    try {
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/auth/verify-reset-otp`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        sessionStorage.setItem("otp_id", JSON.stringify(data.data.otp_id));
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);

export const resetPin = createAsyncThunk(
  "forgotpassword/resetPin",
  async (body, thunkAPI) => {
    try {
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/auth/reset-pin`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        sessionStorage.setItem("otp_id", JSON.stringify(data.data.otp_id));
        return data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue({
        message: "Failed! Unable to complete your request.",
      });
    }
  }
);
