import React from "react";
import { BiSearchAlt } from "react-icons/bi";
import styled from "styled-components";

const Searchbar = (props) => (
  <SearchBar>
    <form action="">
      <BiSearchAlt className="icon" />
      <input
        type="text"
        placeholder={props?.placeholder}
        onChange={props?.onChange}
      />
    </form>
  </SearchBar>
);

const SearchBar = styled.div`
  position: relative;
  width: 100%;
  form {
    display: flex;
    align-items: center;
    position: relative;
    height: 100%;

    .icon {
      position: absolute;
      // top: 0.4em;
      right: 0.3em;
      transfrom: translateX(-50%);
      width: 1.5rem;
      height: 1.5rem;
      cursor: pointer;
      color: #999;

      :hover {
        transform: scale(1.1);
      }
    }

    input {
      background: transparent;
      border: none;
      padding: 1em 3.5em 1em 1em;
      height: 100%;
      border-radius: 5px;
      font-style: normal;
      font-weight: 500;
      font-size: 1.1rem;
      letter-spacing: 0.018em;
      color: #6A7B81;
      // max-width: 365px;
      width: 100%;

      :focus {
        outline: none;
        border: none;
      }
    }
  }
`;

export default Searchbar;
