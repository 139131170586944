import React from "react";
import styled from "styled-components";
import {
  BettyUnCheckboxCircleIcon,
  BettyCheckboxCircleIcon,
} from "../assets/icons";

const BettyCheckbox = (props) => {
  return (
    <Container onClick={props.handleClick}>
      {props.selected ? (
        <BettyCheckboxCircleIcon />
      ) : (
        <BettyUnCheckboxCircleIcon />
      )}
      <p>{props.text}</p>
    </Container>
  );
};

export default BettyCheckbox;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 1rem 0;
  cursor: pointer;

  p {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #2a434b8b;
    padding: 0;
    margin: 0;
  }

  img {
    width: 28px;
    height: 28px;
  }
`;
