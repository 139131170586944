import { createSlice } from "@reduxjs/toolkit";
import { countryDialInfo } from "../../utilities/countryDialInfo";
import { getLocation, getLocaleProfile } from "../actions/getLocation";

export const getLocationSlice = createSlice({
  name: "getLocation",
  initialState: {
    loading: false,
    success: false,
    error: false,
    showModal: false,
    location: {
      code: "",
      currency: "",
      info: {},
      profile: {},
    },
  },
  reducers: {
    setLoading: (state) => {
      state.loading = !state.loading;
    },
    handleShowModal: (state) => {
      state.showModal = !state.showModal;
      return state;
    },
    setLocation: (state, { payload }) => {
      state.location.currency = payload.currency;
      state.location.code = payload.country_code.toLowerCase();
      state.location.info = countryDialInfo.find(
        (country) => country.country_code === payload.country_code
      );
      setTimeout(() => {
        window.location.reload();
      }, 100);
      return state;
    },
  },
  extraReducers: {
    [getLocation.pending]: (state) => {
      state.loading = true;
      return state;
    },
    [getLocation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.success = true;
      state.error = false;
      if (
        payload.country_code === "NG" ||
        payload.country_code === "GH" ||
        payload.country_code === "CD" ||
        payload.country_code === "ZM"
      ) {
        state.showModal = false;
        state.location.currency = payload.currency;
        state.location.code = payload.country_code.toLowerCase();
        state.location.info = countryDialInfo.find(
          (country) =>
            country.country_code === payload.country_code.toLowerCase()
        );
      } else {
        state.showModal = !state.showModal;
      }

      return state;
    },
    [getLocation.rejected]: (state) => {
      state.loading = false;
      state.success = false;
      state.error = true;
      return state;
    },
    [getLocaleProfile.pending]: (state) => {
      // state.loading = true;
    },
    [getLocaleProfile.fulfilled]: (state, { payload }) => {
      state.location.profile = payload;
      return state;
    },
  },
}); 

export const { handleShowModal, setLocation, setLoading } =
  getLocationSlice.actions;
export const getLocationSelector = (state) => state.getLocation;
