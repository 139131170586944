import { createSlice } from "@reduxjs/toolkit";
import { getVerifyPhoneOtp } from "../../../actions/auth/signup";

export const verifyPhoneSlice = createSlice({
  name: "verifyPhone",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
    otp_id: null,
  },
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      state.otp_id = null;
      return state;
    },
  },
  extraReducers: {
    [getVerifyPhoneOtp.pending]: (state) => {
      state.loading = true;
    },
    [getVerifyPhoneOtp.fulfilled]: (state, { payload }) => {
      state.success = true;
      state.error = false;
      state.errors = null;
      state.loading = false;
      state.otp_id = payload.otp_id;
      return state;
    },
    [getVerifyPhoneOtp.rejected]: (state, { payload }) => {
      state.errors = payload.message;
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});
export const { clearState } = verifyPhoneSlice.actions;

export const verifyPhoneSelector = (state) => state.verifyPhone;
