import { createAsyncThunk } from "@reduxjs/toolkit";

export const validateBankAccount = createAsyncThunk(
  "validate/bank/account",
  async (body, thunkAPI) => {
    try {
      const response = await fetch(
        `https://api.paystack.co/bank/resolve?account_number=${body?.account_number}&bank_code=${body?.bank_code}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}`,
          },
        }
      );
      let data = await response.json();
      if (data.status === true) {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);

export const addBankAccount = createAsyncThunk(
  "add/bank/account",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/withdraw-options`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);

export const getBankAccounts = createAsyncThunk(
  "get/bank/accounts",
  async (getErrors, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/withdraw-options`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);

export const verifyUserWithdrawal = createAsyncThunk(
  "verify/withdrawal/otp",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/verify-withdraw-request`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);

export const getWithdrawalOtp = createAsyncThunk(
  "get/withdrawal/otp",
  async (phone, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/auth/resend-otp`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(phone),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);

export const getWithdrawalMailOtp = createAsyncThunk(
  "get/withdrawal/otp",
  async (email, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/auth/resend-email-otp`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(email),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);

export const createWithdrawal = createAsyncThunk(
  "create/withdrawal",
  async (body, thunkAPI) => {
    try {
      let tokenData = JSON.parse(sessionStorage.getItem("betty_tkd"));
      const { location } = JSON.parse(
        localStorage.getItem("persist:getLocation")
      );
      const code = JSON.parse(location).info.code;
      const rememberSession = localStorage.getItem("rememberSession");
      if (rememberSession) {
        tokenData = JSON.parse(localStorage.getItem("betty_tkd"));
      }
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/${code}/${process.env.REACT_APP_API_VERSION}/user/withdraw-request`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenData?.accessToken}`,
          },
          body: JSON.stringify(body),
        }
      );
      let data = await response.json();
      if (data.status === "success") {
        return data.data;
      } else {
        return thunkAPI.rejectWithValue(data);
      }
    } catch (err) {
      return thunkAPI.rejectWithValue([
        {
          message: "Failed! To establish internet connection.",
        },
      ]);
    }
  }
);
