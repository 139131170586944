import { createSlice } from "@reduxjs/toolkit";
import { setUserPin } from "../../../actions/auth/signup";

export const setPinSlice = createSlice({
  name: "setPin",
  initialState: {
    loading: false,
    success: false,
    error: false,
    errors: null,
  },
  reducers: {
    clearState: (state) => {
      state.success = false;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
  },
  extraReducers: {
    [setUserPin.pending]: (state) => {
      state.loading = true;
    },
    [setUserPin.fulfilled]: (state) => {
      state.success = true;
      state.error = false;
      state.errors = null;
      state.loading = false;
      return state;
    },
    [setUserPin.rejected]: (state, { payload }) => {
      state.errors = payload.message;
      state.success = false;
      state.loading = false;
      state.error = true;
      return state;
    },
  },
});

export const { clearState } = setPinSlice.actions;

export const setPinSelector = (state) => state.setPin;
